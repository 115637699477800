import axios from 'axios'
import { createApp } from 'vue'
import {
  Avatar,
  Breadcrumb,
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Icon,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Popconfirm,
  Popover,
  Radio,
  Select,
  Steps,
  Switch,
  Tabs,
  Tag,
  Tooltip,
  Upload,
} from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/ant.css'
import './assets/css/main.css'

const app = createApp(App)

/**
 * Register UI components.
 */
app.use(Avatar)
app.use(Breadcrumb)
app.use(Button)
app.use(Checkbox)
app.use(DatePicker)
app.use(Drawer)
app.use(Dropdown)
app.use(Form)
app.use(Icon)
app.use(Input)
app.use(InputNumber)
app.use(Menu)
app.use(Modal)
app.use(Popconfirm)
app.use(Popover)
app.use(Radio)
app.use(Select)
app.use(Steps)
app.use(Switch)
app.use(Tabs)
app.use(Tag)
app.use(Tooltip)
app.use(Upload)

app.use(router)
app.use(store)

app.mount('#app')

app.config.globalProperties.$http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
})
app.config.globalProperties.$confirm = Modal.confirm
app.config.globalProperties.$message = message
app.config.globalProperties.$error = Modal.error
app.config.globalProperties.$warning = Modal.warning
