import { createRouter, createWebHistory } from 'vue-router'
import store from './store'

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    component: () => import('@/components/TheDashboard/TheDashboard.vue'),
    children: [{
      path: '',
      name: 'index',
      meta: { title: 'Home', requiresAuth: true },
      component: () => import('@/views/index.vue')
    }, {
      path: '/p',
      name: 'publications',
      meta: { title: 'All Publications', requiresAuth: true },
      component: () => import(/* webpackChunkName: "publications" */ '@/views/publications/index.vue')
    }, {
      path: '/p/:publication',
      component: () => import('@/components/ThePublication/ThePublication.vue'),
      children: [{
        path: '',
        name: 'publications-id',
        meta: { title: 'Publication Home', requiresAuth: true },
        component: () => import(/* webpackChunkName: "publications-id" */ '@/views/publications/_id/index.vue')
      }, {
        path: 'actions',
        name: 'publications-id-actions',
        meta: { title: 'Call-to-Actions', requiresAuth: true },
        component: () => import(/* webpackChunkName: "publications-id-actions" */ '@/views/publications/_id/actions.vue')
      }, {
        path: 'articles',
        name: 'publications-id-articles',
        meta: { title: 'Articles', requiresAuth: true },
        component: () => import(/* webpackChunkName: "publications-id-articles" */ '@/views/publications/_id/articles/index.vue')
      }, {
        path: 'articles/:article',
        name: 'publications-id-articles-id',
        meta: { title: 'Edit Article', requiresAuth: true },
        component: () => import(/* webpackChunkName: "publications-id-articles" */ '@/views/publications/_id/articles/_id.vue')
      }, {
        path: 'extensions',
        name: 'publications-id-extensions',
        meta: { title: 'Extensions', requiresAuth: true },
        component: () => import(/* webpackChunkName: "publications-id-extensions" */ '@/views/publications/_id/extensions/index.vue')
      }, {
        path: 'extensions/:extension',
        name: 'publications-id-extensions-id',
        meta: { title: 'Edit Extension', requiresAuth: true },
        component: () => import(/* webpackChunkName: "publications-id-extensions" */ '@/views/publications/_id/extensions/_id.vue')
      }, {
        path: 'roles',
        name: 'publications-id-roles',
        meta: { title: 'Roles', requiresAuth: true },
        component: () => import(/* webpackChunkName: "publications-id-roles" */ '@/views/publications/_id/roles.vue')
      }, {
        path: 'sections',
        name: 'publications-id-sections',
        meta: { title: 'Sections', requiresAuth: true },
        component: () => import(/* webpackChunkName: "publications-id-sections" */ '@/views/publications/_id/sections.vue')
      }, {
        path: 'settings',
        component: () => import('@/components/ThePublication/ThePublicationSetting.vue'),
        children: [{
          path: '',
          name: 'publications-id-settings',
          meta: { title: 'General', requiresAuth: true },
          component: () => import(/* webpackChunkName: "publications-id-settings" */ '@/views/publications/_id/settings/index.vue'),
        }, {
          path: 'about',
          name: 'publications-id-settings-about',
          meta: { title: 'About', requiresAuth: true },
          component: () => import(/* webpackChunkName: "publications-id-settings" */ '@/views/publications/_id/settings/about.vue')
        }, {
          path: 'invoices',
          name: 'publications-id-settings-invoices',
          meta: { title: 'Invoice History', requiresAuth: true },
          component: () => import(/* webpackChunkName: "publications-id-settings" */ '@/views/publications/_id/settings/invoices.vue')
        }, {
          path: 'keys',
          name: 'publications-id-settings-keys',
          meta: { title: 'API Access Keys', requiresAuth: true },
          component: () => import(/* webpackChunkName: "publications-id-settings" */ '@/views/publications/_id/settings/keys.vue')
        }, {
          path: 'subscription',
          name: 'publications-id-settings-subscription',
          meta: { title: 'Subscription', requiresAuth: true },
          component: () => import(/* webpackChunkName: "publications-id-settings" */ '@/views/publications/_id/settings/subscription.vue')
        }],
      }, {
        path: 'users',
        name: 'publications-id-users',
        meta: { title: 'Users', requiresAuth: true },
        component: () => import(/* webpackChunkName: "publications-id-users" */ '@/views/publications/_id/users.vue')
      }],
    }, {
      path: '/account',
      component: () => import('@/components/TheAccount/TheAccount.vue'),
      children: [{
        path: '',
        name: 'account',
        meta: { title: 'Profile', requiresAuth: true },
        component: () => import(/* webpackChunkName: "account" */ '@/views/account/index.vue'),
      }, {
        path: 'password',
        name: 'account-password',
        meta: { title: 'Password', requiresAuth: true },
        component: () => import(/* webpackChunkName: "account" */ '@/views/account/password.vue'),
      }],
    }],
  }, {
    path: '/register',
    name: 'register',
    meta: { title: 'New Publication' },
    component: () => import('@/views/register/index.vue')
  }, {
    path: '/register/:id',
    name: 'register-id',
    meta: { title: 'Join Publication' },
    component: () => import('@/views/register/_id.vue')
  }, {
    path: '/login',
    name: 'login',
    meta: { title: 'Login' },
    component: () => import('@/views/login/index.vue')
  }, {
    path: '/login/:id',
    name: 'login-id',
    meta: { title: 'Activate Account' },
    component: () => import('@/views/login/_id.vue')
  }, {
    path: '/reset',
    name: 'reset',
    meta: { title: 'Reset Password' },
    component: () => import('@/views/reset/index.vue')
  }, {
    path: '/reset/:id',
    name: 'reset-id',
    meta: { title: 'Reset Password' },
    component: () => import('@/views/reset/_id.vue')
  }, {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: { title: 'Page Not Found' },
    component: () => import(/* webpackChunkName: "404" */ '@/views/404'),
  }],
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = `${ to.meta.title } - Postodian`
  } else {
    document.title = 'Postodian'
  }

  if (!store.state.csrfToken) {
    await store.dispatch('refreshToken')
  }

  if (to.params.publication) {
    if (to.params.publication !== store.state.publication.data.id) {
      try {
        await store.dispatch('publication/retrieve', to.params.publication)
      } catch (err) {
        return store.commit('isBlocked', true)
      }
    }
  } else {
    store.commit('publication/data', {})
  }

  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (store.getters.isAuth) {
      return next()
    } else {
      try {
        if (await store.dispatch('refresh')) {
          return next()
        } else {
          return store.commit('isBlocked', true)
        }
      } catch (err) {
        return next({ name: 'login' })
      }
    }
  }

  return next()
})

export default router
