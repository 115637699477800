export default {
  state() {
    return {
      data: {},
      isError: false,
    }
  },

  mutations: {
    field(state, data) {
      state.data[Object.keys(data)[0]] = data[Object.keys(data)[0]]
    },
    isError(state, status) {
      state.isError = status
    },
  },

  getters: {
    isError(state) {
      return state.isError
    },
  },

  namespaced: true,
}
