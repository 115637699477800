<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    '$store.getters.isBlocked'(value) {
      if (value) {
        this.$warning({
          title: 'Insufficient Privilege',
          content: 'Your current role does not allow you to perform this task. Please check with the administrator of this publication.',
          onOk: () => this.$store.commit('isBlocked', false),
        })
      }
    }
  }
}
</script>
