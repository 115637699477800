import axios from 'axios'

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
})

export default {
  state() {
    return {
      data: {},
    }
  },

  mutations: {
    data(state, data) {
      state.data = data
    },
    address(state, data) {
      state.data.address = data
    },
    field(state, data) {
      state.data[Object.keys(data)[0]] = data[Object.keys(data)[0]]
    },
  },

  actions: {
    async retrieve({ commit }, publication) {
      try {
        var { data } = await http.get(`/publications/${ publication }`)
        commit('data', data)
      } catch (err) {
        throw err
      }
    },
  },

  getters: {
    isAdmin(state) {
      return state.data.role && state.data.role.admin
    },
    permissions(state) {
      return state.data.role && state.data.role.permissions
    },
  },

  namespaced: true,
}
