import axios from 'axios'

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
})

export default {
  state() {
    return {
      data: {},
    }
  },

  mutations: {
    data(state, data) {
      state.data = data
    },
    field(state, data) {
      state.data[Object.keys(data)[0]] = data[Object.keys(data)[0]]
    },
  },

  actions: {
    async retrieve({ commit, rootState }, article) {
      try {
        var { data } = await http.get(`/publications/${ rootState.publication.data.id }/articles/${ article }`)
        commit('data', data)
      } catch (err) {
        throw err
      }
    },
    async update({ commit, rootState, state }) {
      try {
        var { data } = await http.put(`/publications/${ rootState.publication.data.id }/articles/${ state.data.id }`, state.data)
        commit('data', data)
      } catch (err) {
        throw err
      }
    },
  },

  namespaced: true,
}
